import React, { useState, useEffect, useMemo } from 'react';
import { getNewsById } from '../../../services/news';
import AdminNewNews from './nueva';
import { navigate } from 'gatsby';

const EditNewsCategory = ({ params: { id } }: { params: { id: string } }) => {
  const [loading, isLoading] = useState(true);
  const [news, setnews] = useState({
    id: '',
    author: {
      email: '',
      firstName: '',
      image: '',
    },
    caption: '',
    content: '',
    categories: [],
    thumb: '',
    banner: '',
    tags: [],
    comments: undefined,
    slug: '',
    createdAt: '',
    updatedAt: '',
  });

  useEffect(() => {
    isLoading(true);
    fetchDataById();
    isLoading(false);
  }, []);

  const fetchDataById = async () => {
    const dataToUpdate = await getNewsById(id);
    if (!dataToUpdate.data) {
      navigate(`/admin/noticias`);
      return;
    }

    const newsResult = dataToUpdate.data;
    const newsAdapted = {
      ...newsResult,
      categories: newsResult.categories.map((category: any) => category._id.toString()),
      tags: newsResult.tags.map((tag: any) => tag._id.toString()),
      comments: newsResult.comments || []
    }
    setnews(newsAdapted);
  }

  const newComponent = useMemo(() =>
    <AdminNewNews
      id={id}
      author={news.author}
      caption={news.caption}
      content={news.content}
      categories={news.categories}
      thumb={news.thumb}
      banner={news.banner}
      tags={news.tags}
      comments={news.comments}
      slug={news.slug}
      loading={loading}
    />, [news]);

  return newComponent;
}

export default EditNewsCategory;
